import React from "react"
import bandWave from "../assets/images/bg/banner4-wave1.svg"
import waterMark2 from "../assets/images/bg/watermark2-bg.png"
import waterMark3 from "../assets/images/bg/watermark1-bg.png"
import { Link } from "react-scroll"

import literature from "../../assets/illustrations/ageOfNarcism.jpg"
import literature1 from "../../assets/illustrations/thePrincipleOfPoet.jpg"
import literature2 from "../../assets/illustrations/shakespear.jpg"
const Index = () => {
  return (
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img
          src={bandWave}
          className="banner4-wave1 img-fluid"
          alt="Wave image"
        />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave image 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-SPA Cottage Literature-</span>
                <p>Browse the latest literature offering from SPA Cottage</p>
                <div className="button-group gap-5">
                  <Link
                    to="litCollection"
                    smooth={true}
                    offset={-110}
                    duration={100}
                    className="eg-btn btn--primary4 btn--lg"
                    style={{ cursor: "pointer" }}
                  >
                    View Collection
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="team-section2 pb-120 position-relative overflow-hidden"
        id="litCollection"
      >
        <img src={waterMark3} className="watermark1-bg" alt="watermark" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="section-title3 primary4 text-cener">
                <span style={{ marginTop: "10px" }}>-Our Collection-</span>
                <p>Browse the latest in SPA Cottage Literature</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={literature} alt="book" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0CVB1JBXT"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name" style={{ fontSize: "20px" }}>
                    The Age of Narcissism
                  </h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0CVB1JBXT"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={literature1} alt="book 1" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0D44DNX8G"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name" style={{ fontSize: "20px" }}>
                    The Principles of Poetry DI + ID = Ψ Psi
                  </h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0D44DNX8G"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={literature2} alt="book 3" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0DLW1YFCH"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name" style={{ fontSize: "20px" }}>
                    Stratford-upon-Shakespeare and other Lies
                  </h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0DLW1YFCH"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
