import React from "react"
import bandWave from "../assets/images/bg/banner4-wave1.svg"
import waterMark from "../assets/images/bg/watermark2-bg.png"
import waterMark2 from "../assets/images/bg/watermark2-bg.png"
import waterMark3 from "../assets/images/bg/watermark1-bg.png"
import classic from "../../assets/illustrations/TheIrishPercyFrench370X420.jpeg"
import classic1 from "../../assets/illustrations/candleNew.jpg"
import classic2 from "../../assets/illustrations/SecretLives.jpeg"
import classic3 from "../../assets/illustrations/classic4.jpg"
import class4 from "../../assets/illustrations/JohnBoyle.png"
import class5 from "../../assets/illustrations/EmilyLawless.png"
import class6 from "../../assets/illustrations/ThomasMoore.jpg"
import { Link } from "react-scroll"

const Index = () => {
  return (
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img src={bandWave} className="banner4-wave1 img-fluid" alt="Wave" />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-SPA Cottage Classics-</span>
                <p>
                  ‘SPA Cottage Classics’ re-introduces classic literature:
                  ancient and modern; ‘classic’ is <i>not bound</i>{" "}
                  geographically to the misty Emerald Isle alone, and the
                  selection of books will reflect this feature.
                </p>
                <div className="button-group gap-5">
                  <Link
                    to="classicsCollection"
                    smooth={true}
                    offset={-110}
                    duration={100}
                    className="eg-btn btn--primary4 btn--lg"
                    style={{ cursor: "pointer" }}
                  >
                    View Collection
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="service-section4 pt-120 pb-120 position-relative overflow-hidden"
        style={{ paddingTop: "25px", paddingBottom: "25px" }}
      >
        <img
          src={waterMark}
          className="watermark1-bg"
          alt="watermark background"
        />
        <div className="container">
          <div
            class="section-title3 primary4 text-start"
            style={{ marginBottom: "5px" }}
          >
            <span>- Kevin Kiely</span>
          </div>
          <div className="content">
            <p className="para">
              Not unlike some creators of literature themselves, I encountered
              literature at the age of four. Every subsequent encounter is
              unfailingly within that high excitement, and beyond to higher
              states of wellbeing though the uses of literature. I continue,
              progressively in this pursuit. Literature, created generally by
              one person, is nothing less than communal and universal. The
              <i>classic series</i> obviously showcases and proves this as a
              fact. The <i>series</i> highlights existing literature, refocuses
              it with original introductions based on a lifetime, exclusively
              amidst world literature. Introductions are the go-between as
              recommendations to the literature. The element of rediscovery of
              really great literature is foremost, the repackaging of literature
              kitted out with new, daring, fresh, vital, exciting informative
              introductions, reflecting the pristine original excitement of
              first-time experience.
            </p>
            <p className="para">
              A connoisseur, an aficionado, is not so much claiming expert
              status, as giving back riches to whoever wants these riches that
              are not owned by any one person. The universality of literature is
              universally accepted. Literature is, as Dante Alighieri proclaims
              a secondary creation, another Nature parallel to Nature and
              Creation. This is all provable in the content of the
              introductions, and, of course first hand in the original works
              themselves. The introductions being the yardstick by which the
              literature is ‘weighed and measured’. These introductions hail
              from literature, and of course in praise of it, so as to invite
              every new reader into their discovery of great literature.
              However, there is no great or little in literature; there is
              simply what your preference is and what brings you in, engages you
              with a particular book as necessity: it might well be your
              earliest contact with nursery rhymes or the highest flights of
              poetry; fiction, history or biography, or as in one of our titles,
              the work of a song writer/performer.
            </p>
            <p className="para">
              Literature includes, and always has included the discussion of
              literature. While literature is generally created by the
              autonomous individual author, when it becomes wellknown there is a
              gathering of readers who propel the literature to more readers.
              Thus a book gains repute and fame far more than its creator.
              Literature is what becomes famous, rather than the one who writes
              it. The author is happy that the book goes forward, even if the
              writer is only known by name and in some cases, anonymous. The
              book is said to be eternal when it has ‘lived’ beyond the time
              after its author created it. And, only one aspect of literature is
              its ability to ‘live’ beyond the era in which it was created.
            </p>
            <p className="para">
              In every respect, every discussion about a book, is another
              introduction to a work of literature. However, the{" "}
              <i>Introductions to spa Cottage Classics </i>emerge from my
              absolute passionate connection to literature, my conviction of its
              necessity, its vitality, not merely as leisure activity but the
              extreme vitality as increase, benefice, and gain; mentally,
              spiritually, and not just in terms of wellbeing.
            </p>
            <div class="proj-details-single">
              <div className="challenge-box" style={{ paddingLeft: "5px" }}>
                <div
                  class="section-title3 primary4 text-start"
                  style={{ marginBottom: "5px" }}
                >
                  <span>- Biographical Note</span>
                </div>
                <p className="para">
                  Poet, Critic, Author; PhD., University College Dublin ‘The
                  Patronage of Poetry at Harvard University’s “Edward Woodberry
                  Poetry Room”’; MPhil., Trinity College (Dublin); W. J.
                  Fulbright Scholar in Poetry, Washington (DC); Hon. Fellow in
                  Writing, University of Iowa
                </p>
                <p className="para">
                  Published works include: <i>Quintesse </i> (St Martin’s Press,
                  NY); <i> Breakfast with Sylvia </i>(Lagan Press) awarded
                  Patrick Kavanagh Fellowship in Poetry;{" "}
                  <i> Plainchant for a Sundering</i> (Lapwing Press);{" "}
                  <i>SOS Lusitania </i> (2015);{" "}
                  <i> UCD Belfield Metaphysical: a retrospective</i> (2018);{" "}
                  <i> Seamus Heaney and the Great Poetry Hoax </i>(2018);{" "}
                  <i> Harvard’s Patron: Jack of All Poets</i> (2018);{" "}
                  <i> Arts Council Immortals</i> (2020);{" "}
                  <i> Cromwell Milton Collins Carson </i>(2020);{" "}
                  <i>
                    {" "}
                    Endgames: Good Friday Agreement & Missus Windsor’s Hitmen{" "}
                  </i>
                  (2020);{" "}
                  <i>
                    {" "}
                    I shot the President’s verse: Selected Literary Journalism
                  </i>
                  (2021); <i> Yrland Regained</i> :Central Cantos Vols I&II;{" "}
                  <i>Hôtel Baudelaire: reservations and cancellations</i>{" "}
                  (2022); <i> Three Plays for Heroines </i>(2022); Bisto Award
                  for A <i>Horse Called El Dorado </i> (O’Brien Press); 5 Arts
                  Council Literature Bursary Awards. Literary Editor{" "}
                  <i> Books Ireland</i>; Visiting Professor of Poetry &
                  Literature, Boise State U & University of Idaho;
                  Writers-in-Schools Scheme (Ireland locations): Creative
                  Writing Workshops Director (Ireland locations); Poetry
                  Readings: Ireland, Italy, France, United States
                </p>
                <p className="para">
                  Reviews/Essays/Articles on Poetry and Literature in following
                  publications:{" "}
                  <i>
                    Hibernia, Irish Examiner, The Democrat Arts Page, Irish
                    Studies Review, Honest Ulsterman, Fortnight
                  </i>
                  , Books Ireland Critic (1991-2019),{" "}
                  <i>
                    {" "}
                    The London Magazine, The Irish Book Review, Poetry Ireland
                    Review, Irish Times, The Irish Independent, Irish Arts
                    Review, Inis, Irish Literary Review, Idaho Arts Quarterly,
                    Humanities{" "}
                  </i>
                  (DC),{" "}
                  <i>
                    {" "}
                    Village Magazine, The Journal of the E. E. Cummings Society,
                    The Wallace Stevens Journal, The Robert Frost Review.
                  </i>
                  <a
                    href="http://www.kevinkiely.net"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    www.kevinkiely.net
                  </a>{" "}
                  kevinkiely-poetwiki
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="team-section2 pb-120 position-relative overflow-hidden"
        id="classicsCollection"
      >
        <img src={waterMark3} className="watermark1-bg" alt="watermark" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="section-title3 primary4 text-cener">
                <span>-Our Collection-</span>
                <p>Browse the latest in SPA Cottage classics</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.8s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={classic1} alt="book" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0BKYK2764"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">The Candle Of Vision</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0BKYK2764"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={classic} alt="book 1" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/Irish-Percy-French-ebook/dp/B0BK1LPCW7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">The Irish Percy French</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/Irish-Percy-French-ebook/dp/B0BK1LPCW7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={classic2} alt="book 2" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0BY2SG9NQ"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                      <li>
                        <a
                        // href="https://www.amazon.co.uk/dp/B0BY2SG9NQ"
                        // target="_blank"
                        // rel="noopener noreferrer"
                        >
                          {/* <i className="bx bx-info-circle"></i> */}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">Secret Lives</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0BY2SG9NQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img
                    src={classic3}
                    alt="book 3"
                    style={{ height: "530px" }}
                  />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/Selected-Poems-Jane-Elgee-Wilde/dp/B0C9S5R84P"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                      <li>
                        <a></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">
                    The Selected Poems of Jane Elgee-Wilde
                  </h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/Selected-Poems-Jane-Elgee-Wilde/dp/B0C9S5R84P"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                    <div
                      className="section-title3  text-cener"
                      style={{
                        marginBottom: "0px",
                        paddingTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        href="https://villagemagazine.ie/the-call-of-the-wildes/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span style={{ color: "black" }}>
                          The call of the Wildes - Village Magazine
                        </span>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={class4} alt="book 3" style={{ height: "530px" }} />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0CVBGS97H"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                      <li>
                        <a></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">
                    John Boyle O’Reilly: Definitive Selected Poems
                  </h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0CVBGS97H"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={class5} alt="book 6" style={{ height: "530px" }} />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0DP4KB27Q"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                      <li>
                        <a></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">Emily Lawless: Selected Poems</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0DP4KB27Q"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
                style={{ padding: "20px" }}
              >
                <div className="team-image">
                  <img src={class6} alt="book 7" style={{ height: "530px" }} />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0D4LXMNX6"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                      </li>
                      <li>
                        <a></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">Thomas Moore: Political Poems</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0D4LXMNX6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
